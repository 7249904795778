* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}
body {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
}
.hidden-scroll::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Ẩn spinner trên Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.two-lines-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em; 
  max-height: 3em; 
}
.one-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em; 
  max-height: 3em;
}
a {
  text-decoration: none;
}
.round-loading {
  animation: round-loading 0.5s ease-in-out infinite;
}
.no-resize {
  resize: none;
}
@keyframes round-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* react data table component */
.rdt_TableRow:not(:first-of-type) {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #00000022;
}
.rdt_TableRow {
  border-bottom: none !important;
}
